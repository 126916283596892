:root {
  --pf-v5-global--FontSize--md: 14px;
  --pf-global--FontSize--md: 14px;
  --pf-v5-global--FontSize--sm: 13px;
  --pf-global--FontSize--sm: 13px;
}

.pf-v5-c-nav .oc-nav-header {
  border-bottom: 1px solid var(--pf-v5-global--BackgroundColor--dark-200);
  margin-top: var(--pf-v5-global--spacer--sm);
  padding: .6rem var(--pf-v5-global--spacer--sm);
}

:where(.pf-v5-theme-dark) .pf-v5-c-nav .oc-nav-header {
  border-bottom-color: var(--pf-v5-global--BorderColor--100);
}

@media screen and (min-width: 1200px) {
  .pf-v5-c-nav .oc-nav-header {
    padding-left: var(--pf-v5-global--spacer--md);
    padding-right: var(--pf-v5-global--spacer--md);
  }
}

.pf-v5-c-nav .oc-nav-header .pf-v5-c-menu {
  --pf-v5-c-menu--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
  padding: var(--pf-v5-global--spacer--sm) 0;
}

:where(.pf-v5-theme-dark) .pf-v5-c-nav .oc-nav-header .pf-v5-c-menu {
  --pf-v5-c-menu--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-300);
}

.pf-v5-c-nav .oc-nav-header .pf-v5-c-menu__item {
  color: var(--pf-v5-global--Color--100);
  padding: var(--pf-v5-global--spacer--sm);
}

.pf-v5-c-nav .oc-nav-header .pf-v5-c-menu__item::after,
.pf-v5-c-nav .oc-nav-header .pf-v5-c-menu__item::before {
  border-style: hidden;
}

.pf-v5-c-nav .oc-nav-header .pf-v5-c-menu__item:hover,
.pf-v5-c-nav .oc-nav-header .pf-v5-c-menu__item:active,
.pf-v5-c-nav .oc-nav-header .pf-v5-c-menu__item:focus,
.pf-v5-c-nav .oc-nav-header .pf-v5-c-menu__item:focus-within,
.pf-v5-c-nav .oc-nav-header .pf-v5-c-menu__list-item:hover,
.pf-v5-c-nav .oc-nav-header .pf-v5-c-menu__list-item:active,
.pf-v5-c-nav .oc-nav-header .pf-v5-c-menu__list-item:focus,
.pf-v5-c-nav .oc-nav-header .pf-v5-c-menu__list-item:focus-within {
  --pf-v5-c-menu__list-item--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
  --pf-v5-c-menu__list-item--Color: var(--pf-v5-global--Color--100, inherit);
}

:where(.pf-v5-theme-dark) .pf-v5-c-nav .oc-nav-header .pf-v5-c-menu__item:hover,
:where(.pf-v5-theme-dark) .pf-v5-c-nav .oc-nav-header .pf-v5-c-menu__item:active,
:where(.pf-v5-theme-dark) .pf-v5-c-nav .oc-nav-header .pf-v5-c-menu__item:focus,
:where(.pf-v5-theme-dark) .pf-v5-c-nav .oc-nav-header .pf-v5-c-menu__item:focus-within,
:where(.pf-v5-theme-dark) .pf-v5-c-nav .oc-nav-header .pf-v5-c-menu__list-item:hover,
:where(.pf-v5-theme-dark) .pf-v5-c-nav .oc-nav-header .pf-v5-c-menu__list-item:active,
:where(.pf-v5-theme-dark) .pf-v5-c-nav .oc-nav-header .pf-v5-c-menu__list-item:focus,
:where(.pf-v5-theme-dark) .pf-v5-c-nav .oc-nav-header .pf-v5-c-menu__list-item:focus-within {
  background-color: var(--pf-global--BackgroundColor--400);
}

.pf-v5-c-nav .oc-nav-header .pf-v5-c-menu-toggle::after,
.pf-v5-c-nav .oc-nav-header .pf-v5-c-menu-toggle::before {
  border-style: hidden;
}

:where(.pf-v5-theme-dark) .pf-v5-c-nav .oc-nav-header .pf-v5-c-menu-toggle {
  --pf-v5-c-menu-toggle--BackgroundColor: transparent;
}

.pf-v5-c-nav .oc-nav-header .pf-v5-c-menu-toggle.pf-m-expanded {
  --pf-v5-c-menu-toggle--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
}

:where(.pf-v5-theme-dark) .pf-v5-c-nav .oc-nav-header .pf-v5-c-menu-toggle.pf-m-expanded {
  background-color: var(--pf-v5-global--BackgroundColor--dark-300);
}

.pf-v5-c-nav .oc-nav-header .pf-v5-c-menu-toggle .pf-v5-c-title.pf-m-md {
  font-family: var(--pf-v5-global--FontFamily--text);
  font-size: 16px;
}

.pf-v5-c-nav .oc-nav-header__menu-toggle--is-empty {
  cursor: default !important;
}

