@import '~@patternfly/patternfly/sass-utilities/all';
@import '~@console/internal/style/vars';

.pf-v5-c-nav .oc-nav-header {
  border-bottom: 1px solid var(--pf-v5-global--BackgroundColor--dark-200);
  margin-top: var(--pf-v5-global--spacer--sm);
  padding: 0.6rem var(--pf-v5-global--spacer--sm);

  :where(.pf-v5-theme-dark) & {
    // Match --pf-v5-c-nav__item--before--BorderColor
    border-bottom-color: var(--pf-v5-global--BorderColor--100);
  }

  @media screen and (min-width: $pf-v5-global--breakpoint--xl) {
    padding-left: var(--pf-v5-global--spacer--md);
    padding-right: var(--pf-v5-global--spacer--md);
  }

  .pf-v5-c-menu {
     --pf-v5-c-menu--BackgroundColor: var(--pf-v5-global--BackgroundColor--100);
     padding: var(--pf-v5-global--spacer--sm) 0;
     :where(.pf-v5-theme-dark) & {
       --pf-v5-c-menu--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-300);
     }
  }

  .pf-v5-c-menu__item {
    color: var(--pf-v5-global--Color--100);
    padding: var(--pf-v5-global--spacer--sm);
    &::after,&::before {
      border-style: hidden;
    }
  }

  .pf-v5-c-menu__item,
  .pf-v5-c-menu__list-item {
    &:hover,&:active,&:focus,&:focus-within {
    --pf-v5-c-menu__list-item--BackgroundColor: var(--pf-v5-global--BackgroundColor--200);
    --pf-v5-c-menu__list-item--Color: var(--pf-v5-global--Color--100, inherit);
      :where(.pf-v5-theme-dark) & {
        background-color: var(--pf-global--BackgroundColor--400);
      }
    }
  }

  .pf-v5-c-menu-toggle {
    &::after, &::before {
      border-style: hidden;
    }

    :where(.pf-v5-theme-dark) & {
      --pf-v5-c-menu-toggle--BackgroundColor: transparent;
    }

    &.pf-m-expanded {
      --pf-v5-c-menu-toggle--BackgroundColor: var(--pf-v5-global--BackgroundColor--dark-200);
      :where(.pf-v5-theme-dark) & {
        background-color: var(--pf-v5-global--BackgroundColor--dark-300);
      }
    }

    .pf-v5-c-title.pf-m-md {
      font-family: var(--pf-v5-global--FontFamily--text);  // Use RedHatText to match side nav links because buttons by default use RedHatDisplay
      font-size: $co-side-nav-section-font-size;
    }
  }

  &__menu-toggle--is-empty {
    cursor: default !important;
  }
}
